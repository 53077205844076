<template>
  <div id="starts">
    <div class="left">
      <div class="title">
        <img
          src="../../img/板块图标 - 副本_slices/icon.png"
          style="width: 0.18rem"
          alt=""
        />
        <span>课程列表</span>
      </div>
      <!-- <el-select
        v-model="option"
        placeholder="全部班级课程"
        style="width: 2.15rem; margin-left: 0.12rem"
      >
        <el-option label="铁道供电A1901班" value="铁道供电A1901班"></el-option>
        <el-option label="铁道供电A1902班" value="铁道供电A1902班"></el-option>
        <el-option label="铁道供电A1903班" value="铁道供电A1903班"></el-option>
        <el-option label="铁道供电A1904班" value="铁道供电A1904班"></el-option>
      </el-select> -->
      <!-- 无课程时显示 -->
      <div class="box" v-if="course.length == 0">
        <img
          src="../../img/上课/暂无数据 (1).png"
          alt=""
          style="width: 113px; height: 61px"
        />
        <div class="txt">当前暂无创建课程~</div>
      </div>
      <!-- 课程列表 -->
      <ul class="classList">
        <li
          @click="
            num = index;
            courseName = item.course_name;
            getId(item.id);
          "
          v-for="(item, index) in course"
          :key="index"
        >
          <div class="line" :class="{ active: num == index }"></div>
          <span>{{ item.course_name }}</span>
          <div class="right">
            <img src="../../img/上课/r.png" alt="" style="width: 14px" />
          </div>
        </li>
      </ul>
      <div class="add">
        <div class="addbtn" @click="addClasses">添加新课程</div>
      </div>
    </div>
    <div class="right">
      <!-- <div class="routerlink">
        <span>当前位置：</span>
        <router-link to="/main/starts">我的课程</router-link>
        <router-link to="/main/starts">/{{ courseName }}</router-link>
      </div> -->
      <div class="not-img" v-if="section.length == 0">
        <img src="../../img/板块图标 - 副本_slices/04_无数据.png" alt="" />
        <div class="txt">您还未发布任何授课场景~</div>
      </div>
      <ul class="middle">
        <li v-for="(item, index) in section" :key="index">
          <div class="img">
            <img :src="item.cover" alt="" />
          </div>
          <div class="info">
            <div class="title">{{ item.course_section_name }}</div>
            <div class="infobtn">
              <!-- <router-link
                v-if="tag == 1"
                :to="{ path: '/hall', query: { csid: item.id } }"
                >上课</router-link
              > -->
              <div
                class="btn"
                @click="
                  dialogTableVisible = true;
                  getclassList(item.id);
                "
              >
                开始上课
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <el-dialog
      title="请选择本次上课班级"
      class="dia"
      :visible.sync="dialogTableVisible"
    >
      <div class="box">
        <b>选择班级</b>
        <el-select
          v-model="value"
          multiple
          placeholder="请选择上课班级"
          style="margin-bottom: 0.35rem"
          @change="changes"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.class_name"
            :value="item.class_name"
          >
          </el-option>
        </el-select>
        <span @click="dia1 = true">创建班级</span>
        <div class="addbtn">
          <div class="btn" @click="choseclass()">确认</div>
          <div class="btn1" @click="dialogTableVisible = false">取消</div>
          <!-- <div class="btn" @click="addclass()">添加班级</div> -->
        </div>
      </div>
    </el-dialog>
    <el-dialog title="添加班级" class="dia dia1" :visible.sync="dia1">
      <div class="box">
        <div class="box1">
          <b>班级名称：</b>
          <el-input
            v-model="classname"
            class="name"
            placeholder="请输入班级名称"
            style="width: 2.5rem"
          ></el-input>
        </div>
        <b>班级人数：</b>
        <el-input
          v-model="classnum"
          placeholder="请输入班级人数"
          type="text"
          oninput="value=value.replace(/[^\d]/g,'')"
          style="width: 2.5rem"
        ></el-input>

        <div class="addbtn">
          <div class="btn" @click="addclass()">添加班级</div>
          <div class="btn1" @click="dia1 = false">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      num: 0,
      course: "",
      section: "",
      info: "",
      // option: "",
      // courseName: "",
      dialogTableVisible: false, // 选择班级
      dia1: false, // 添加班级
      classname: "",
      classnum: "",
      options: [],
      value: [],
      class_id: [],
      csid: "",
      cid: "",
    };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    getinfo() {
      util.post("/course/index", {}, (res) => {
        console.log(res);
        if (res.code == 1) {
          this.course = res.data;
          this.getId(res.data[0].id);
        }
      });
    },
    getId(id) {
      util.post("/section/index", { cid: id }, (res) => {
        this.section = res.data.section;
        this.info = res.data;
        localStorage.setItem("cid", id);
      });
    },
    getTid(tid) {
      // console.log(tid);
      // util.post("/catalog", { csid: tid }, (res) => {
      //   console.log(res);
      //   if (res.code == 1) {
      this.$router.push({ path: "/main/addscene", query: { csid: tid } });
      // }
      // });
    },
    del(id) {
      // if (res.code == 1) {
      // }
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          util.post("/course/section_del", { id: id }, (res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addClasses() {
      this.$router.push({
        path: "/main/addclasses",
        query: {
          num: 1,
        },
      });
    },
    // 获取班级列表
    getclassList(id) {
      util.post("/classList", {}, (res) => {
        this.options = res.data;
        this.csid = id;
      });
    },

    changes(value) {
      let arr = [];
      value.forEach((item) => {
        arr.push(this.options.find((i) => i.class_name == item));
      });
      let newArr = [];
      arr.map((item) => {
        newArr.push(item.id);
      });
      this.class_id = newArr;
    },
    // 选择班级
    choseclass() {
      util.post(
        "/CourseStart",
        {
          csid: this.csid,
          class_id: this.class_id,
        },
        (res) => {
          console.log(res);
          if (res.code == 1) {
            localStorage.setItem("md", res.data.md_str);
            localStorage.setItem("class_id", this.class_id);
            localStorage.setItem("value", this.value);
            this.dialogTableVisible = false;
            if (localStorage.getItem("value")) {
              this.$router.push({
                path: "/hallindex",
                query: { csid: this.csid },
              });
            } else {
              return;
            }
          }
        }
      );
    },
    // 添加班级
    addclass() {
      this.change = 1;
      util.post(
        "/classAdd",
        {
          class_name: this.classname,
          class_count: this.classnum,
        },
        (res) => {
          console.log(res);
          this.dia1 = false;
          util.post("/classList", {}, (res) => {
            this.options = res.data;
          });
          this.classname = this.classnum = "";
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
#starts {
  height: 100%;
  display: flex;

  .dia {
    /deep/.el-dialog {
      width: 700px !important;
      height: 400px;
      margin-top: 26vh !important;
    }
    /deep/.el-dialog__header {
      text-align: center;
      color: #2c2c2c;
      font-weight: bold;
      padding: 20px;
      border-bottom: 1px solid#F7F7F7;
    }
    /deep/.el-dialog__body {
      padding: 30px 70px;
    }
    .box {
      b {
        font-size: 14px;
        font-weight: 400;
        margin-right: 21px;
        margin-bottom: 15px;
        display: inline-block;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #1c5eff;
        // margin-left: 13px;
        cursor: pointer;
      }
      .addbtn {
        display: flex;
        justify-content: center;
        margin-top: 95px;
      }
      .btn {
        width: 100px;
        height: 36px;
        background: #1c5eff;
        border-radius: 2px;
        color: #fff;
        margin-bottom: 23px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
      .btn1 {
        width: 100px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #1c5eff;
        border-radius: 2px;
        color: #1c5eff;
        margin-left: 24px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .dia1 {
    /deep/.el-dialog__body {
      padding: 30px 114px !important;
    }
    b {
      margin-right: 45px !important;
    }
    .box1 {
      margin-bottom: 21px;
    }
  }
  .left {
    width: 2.38rem /* 238/100 */;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-top: none;
    border-bottom: none;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 82px;
      img {
        width: 36px;
        height: 45px;
      }
      .txt {
        font-size: 14px;
        font-weight: 400;
        color: #8a8e99;
        margin-top: 19px;
      }
    }
    .title {
      width: 2.36rem;
      height: 0.68rem /* 68/100 */;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0.14rem /* 14/100 */;
      border-bottom: 1px solid #cdcdcd;
      span {
        font-size: 0.2rem /* 20/100 */;
        font-weight: bold;
        margin-left: 0.08rem /* 8/100 */;
        color: #131313;
      }
    }
    .classList {
      font-size: 0.14rem /* 14/100 */;
      font-weight: 500;
      color: #000000;
      line-height: 0.63rem /* 63/100 */;
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0.17rem /* 17/100 */;
        cursor: pointer;

        span {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        // }
        .line {
          width: 0.05rem /* 5/100 */;
          height: 0.23rem /* 23/100 */;
          margin-right: 0.32rem /* 32/100 */;
        }
        .right {
          width: 10px;
        }
      }
      li:hover {
        .right {
          color: skyblue;
        }
      }
    }
    .add {
      display: flex;
      justify-content: center;
      margin-top: 1.2rem /* 120/100 */;
      .addbtn {
        width: 1.4rem /* 140/100 */;
        height: 0.45rem /* 45/100 */;
        background-color: #1c5eff;
        border-radius: 0.05rem /* 5/100 */;
        color: #fcfcfc;
        font-size: 0.15rem /* 15/100 */;
        line-height: 0.45rem /* 45/100 */;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .right {
    // display: flex;
    width: 100%;

    .routerlink {
      height: 39/96in;
      line-height: 39/96in;
      border-bottom: 1px solid #dbdbdb;
      font-size: 14px;
      padding-left: 30px;
    }
    .not-img {
      width: 413px;
      position: absolute;
      top: 370px;
      left: 794px;
      img {
        width: 100%;
      }
      .txt {
        font-size: 14px;
        font-weight: 400;
        color: #b8b8b8;
        line-height: 16px;
        text-align: center;
      }
    }
  }
  .middle {
    width: 100%;
    height: calc(100% - 0.40625in);
    padding: 34px 0.2rem /* 20/100 */ 0 0.47rem /* 47/100 */;
    > li {
      float: left;
      width: 2.6rem /* 260/100 */;
      height: 2.06rem /* 206/100 */;
      border-radius: 0.05rem /* 5/100 */;
      background-color: #fff;
      margin-right: 0.54rem /* 54/100 */;
      margin-bottom: 0.38rem /* 38/100 */;
      font-size: 0.14rem /* 14/100 */;
      .img {
        width: 2.6rem /* 260/100 */;
        height: 1.45rem /* 145/100 */;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .info {
        height: 0.77rem /* 77/100 */;
        padding: 0 0.14rem /* 14/100 */ 0.1rem /* 10/100 */;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .infobtn {
          .btn {
            cursor: pointer;
            width: 70px;
            height: 36px;
            background: #1c5eff;
            border-radius: 3px;
            color: #fff;
            text-align: center;
            line-height: 36px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
/deep/.el-select {
  margin-bottom: 0.35rem;
  width: 3.8rem;
  margin-right: 10px;
}
@media screen and (max-width: 1280/96in) {
  .functions {
    width: 51.19/96in !important;
  }
  .mains {
    #starts > .left {
      width: 158.66/96in !important;
      .title {
        width: 156.66/96in;
      }
      .classList {
        .more {
          width: 13.33/96in !important;
        }
        .li {
          .text {
            width: 108.66/96in !important;
          }
        }
      }
    }
    // .right {
    //   width: 186/96in !important;
    // }
  }
  .middle {
    > li {
      width: 173.33/96in !important;
      height: 137.33/96in !important;
      .img {
        width: 173.33/96in !important;
        height: 97.5/96in !important;
      }
      .info {
        .title {
          line-height: 20/96in !important;
        }
      }
    }
  }
}
// .el-dropdown-menu {
//   box-shadow: 0px 4px 23px 0px rgba(28, 94, 255, 0.1);
//   font-size: 0.14rem /* 14/100 */;
//   color: #575759;
// }
.active {
  background-color: #1c5eff;
}
</style>